import React from "react";
import { graphql } from "gatsby";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Breadcrumbs from "../components/Navigation/breadcrumbs";
import Seo from "../components/seo";

const PrivacyPolicy = ({ path, location, data: { mdx } }) => {
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: mdx.frontmatter.title,
  });
  return (
    <>
      <Seo
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        pathname={path}
      />
      <Breadcrumbs crumbs={crumbs} />
      <div className="container">
        <h1 className="page-title">{mdx?.frontmatter?.title}</h1>
      </div>
      <div className="container  privacy-policy">
        <MDXProvider>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </div>
    </>
  );
};
export default PrivacyPolicy;

export const query = graphql`
  query PrivacyPolicy($locale: String!, $slug: String!) {
    mdx(fields: { locale: { eq: $locale }, slug: { eq: $slug } }) {
      frontmatter {
        title
        description
      }
      body
    }
  }
`;
